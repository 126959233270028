import './App.css';
import React, { lazy, Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
// Lazy load components
const Home = lazy(() => import('./components/Home/home'));
const VideosList = lazy(() => import('./components/Content/Videos/videosList'));
const VideoDetailView = lazy(() => import('./components/Content/Videos/videoDetailView'));
const GlobalQuiz = lazy(() => import('./components/Content/Quiz/GlobalQuiz'));
const FlashCards = lazy(() => import('./components/Content/FlashCards/FlashCards'));
const Spellings = lazy(() => import('./components/Content/Spellings/Spellings'));
const Tests = lazy(() => import('./components/Content/Tests/Tests'));
const TestsView = lazy(() => import('./components/Content/Tests/TestView'));
const QuizView = lazy(() => import('./components/Content/Quiz/QuizView'));
const FlashCardView = lazy(() => import('./components/Content/FlashCards/flashCardView'));
const SpellingsView = lazy(() => import('./components/Content/Spellings/SpellingsView'));
const SchoolQuiz = lazy(() => import('./components/Content/SchoolQuiz/SchoolQuiz'));
const MarketingBanners = lazy(() => import('./components/Content/MarketingBanners/marketingBanners'));
const Qams = lazy(() => import('./components/Content/QA/qams'));
const Grade = lazy(() => import('./components/Master/Grade/grade'));
const Concept = lazy(() => import('./components/Master/Concept/concept'));
const QuizAssets = lazy(() => import('./components/Master/QuizAssets/quizAssets'));
const QuestionAnsList = lazy(() => import('./components/Master/QuestionAnswersGlobal/questionAns'));
const QuestionAnsListUser = lazy(() => import('./components/Master/QuestionAnswersUsers/questionAnsUser'));
const QuestionAnsListgpt = lazy(() => import('./components/Master/QuestionAnswersGpt/questionAnsGpt'));
const SystemUsers = lazy(() => import('./components/Users/SystemUsers/systemUsers'));
const UsersList = lazy(() => import('./components/Users/Userslist/usersList'));
const Database = lazy(() => import('./components/Developer/Database/database'));
const Logs = lazy(() => import('./components/Developer/Log/logs'));
const TokenUsage = lazy(() => import('./components/Developer/TokenMonitor/tokenUsage'));
const Homepage = lazy(() => import('./components/Developer/Homepage/Homepage'));
const HomePageSections = lazy(() => import('./components/Developer/Homepage/HomePageSections'));
const Login = lazy(() => import('./components/login/login'));
const UserLogin = lazy(() => import('./components/login/userLogin')); 
const PageNotFound = lazy(() => import('./components/pageNotFound/pageNotFound'));
const ErrorComponent = lazy(() => import('./components/errorComponent/ErrorComponent'));
const MainView = lazy(() => import('./mainview'));
const ProtectedRoute = lazy(() => import('./protectedRoute'));
const ProtectedLogin = lazy(() => import('./protectedLogin'));

const GoogleImages = lazy(() => import('./components/Master/Images/googleImages'));
const UserImages = lazy(() => import('./components/Master/UsersImages/usersImages'));
const UserQuiz = lazy(() => import('./components/Content/UsersQuiz/UserQuiz'));
const UserQuizView = lazy(() => import('./components/Content/UsersQuiz/QuizView'));
const UserFlashcard = lazy(() => import('./components/Content/UsersFlashCards/usersFlashCards'));
const UserFlashcardView = lazy(() => import('./components/Content/UsersFlashCards/flashCardView'));

const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <ProtectedLogin>
        <Login />
      </ProtectedLogin>
    )
  },
  {
    path: "/userLogin",
    element: <UserLogin />,
  }, 
  {
    element: <MainView />,
    children: [
      { path: "/", 
        element: <ProtectedRoute allowedRoles={[1]}>
                    <Home />
                  </ProtectedRoute> },
      { path: "/home", 
        element: <ProtectedRoute allowedRoles={[1]}>
                  <Home />
                 </ProtectedRoute> },
      { path: "/content/videos",
        element:<ProtectedRoute allowedRoles={[1]}>
                  <VideosList />
                </ProtectedRoute>},
      { path: "/content/videos/detailView/:videoid",
        element: <ProtectedRoute allowedRoles={[1]}>
                  <VideoDetailView />
                </ProtectedRoute> },
      { path: "/content/globalQuiz", 
        element: <ProtectedRoute allowedRoles={[1]}>
                    <GlobalQuiz />
                  </ProtectedRoute> },
      { path: "/content/quiz/view/:quizid", 
        element: <ProtectedRoute allowedRoles={[1]}>
                    <QuizView /> 
                  </ProtectedRoute> },
      { path: "/content/schoolQuiz", 
        element:  <ProtectedRoute allowedRoles={[1]}>
                     <SchoolQuiz />
                  </ProtectedRoute> },
      { path: "/content/marketingBanners", 
        element: <ProtectedRoute allowedRoles={[1]}>
                   <MarketingBanners /> 
                  </ProtectedRoute> },
      { path: "/content/spellings", 
        element: <ProtectedRoute allowedRoles={[1]}>
                    <Spellings /> 
                  </ProtectedRoute> },
      { path: "/content/spellings/view/:fcid", 
        element: <ProtectedRoute allowedRoles={[1]}>
                    <SpellingsView /> 
                  </ProtectedRoute> },
      { path: "/content/tests",
        element: <ProtectedRoute allowedRoles={[1]}>
                   <Tests /> 
                  </ProtectedRoute> },
      { path: "/content/tests/view/:fcid", 
        element: <ProtectedRoute allowedRoles={[1]}>
                    <TestsView /> 
                  </ProtectedRoute> },
      { path: "/content/flashCards", 
        element: <ProtectedRoute allowedRoles={[1]}>
                    <FlashCards /> 
                  </ProtectedRoute> },
      { path: "/content/flashCards/view/:fcid", 
        element: <ProtectedRoute allowedRoles={[1]}>
                    <FlashCardView /> 
                  </ProtectedRoute> },
      { path: "/content/qams", 
        element: <ProtectedRoute allowedRoles={[1]}>
                     <Qams /> 
                  </ProtectedRoute> },
      { path: "/masters/grade", 
        element: <ProtectedRoute allowedRoles={[1]}>
                   <Grade /> 
                  </ProtectedRoute> },
      { path: "/masters/concept", 
        element: <ProtectedRoute allowedRoles={[1]}>
                   <Concept /> 
                  </ProtectedRoute> },
      { path: "/masters/quizassets", 
        element: <ProtectedRoute allowedRoles={[1]}>
                   <QuizAssets /> 
                  </ProtectedRoute> },
      { path: "/masters/questionanswersglobal", 
        element: <ProtectedRoute allowedRoles={[1]}>
                   <QuestionAnsList /> 
                  </ProtectedRoute> },
      { path: "/masters/questionanswersusers", 
        element: <ProtectedRoute allowedRoles={[1,2]}>
                     <QuestionAnsListUser />
                  </ProtectedRoute> },
      { path: "/masters/questionanswersgpt", 
        element: <ProtectedRoute allowedRoles={[1]}>
                   <QuestionAnsListgpt /> 
                  </ProtectedRoute> },
      { path: "/users/usersList", 
        element: <ProtectedRoute allowedRoles={[1]}>
                    <UsersList />
                  </ProtectedRoute> },
      { path: "/users/systemUsers", 
        element: <ProtectedRoute allowedRoles={[1]}>
                   <SystemUsers />
                  </ProtectedRoute> },
      { path: "/developer/database", 
        element: <ProtectedRoute allowedRoles={[1]}>
                   <Database /> 
                  </ProtectedRoute> },
      { path: "/developer/logs", 
        element: <ProtectedRoute allowedRoles={[1]}>
                   <Logs />
                  </ProtectedRoute> },
      { path: "/developer/tokenUsage", 
        element: <ProtectedRoute allowedRoles={[1]}>
                   <TokenUsage /> 
                  </ProtectedRoute> },
      { path: "/developer/homepage", 
        element: <ProtectedRoute allowedRoles={[1]}>
                     <Homepage />
                  </ProtectedRoute> },
      { path: "/developer/homepage/:section_id", 
        element: <ProtectedRoute allowedRoles={[1]}>
                     <HomePageSections />
                  </ProtectedRoute> },
      { path: "/masters/googleImages", 
        element: <ProtectedRoute allowedRoles={[1]}>
                      <GoogleImages />
                  </ProtectedRoute> },
      { path: "/masters/images", 
      element: <ProtectedRoute allowedRoles={[1]}>
                    <GoogleImages />
                </ProtectedRoute> },
      { path: "/masters/userImages", 
      element: <ProtectedRoute allowedRoles={[1,2]}>
                    <UserImages />
                </ProtectedRoute> },
      { path: "/content/userQuiz", 
      element: <ProtectedRoute allowedRoles={[1,2]}>
                    <UserQuiz />
                </ProtectedRoute> },
      { path: "/content/userQuiz/view/:quizid", 
        element: <ProtectedRoute allowedRoles={[1,2]}>
                    <UserQuizView /> 
                  </ProtectedRoute> },
      { path: "/content/userFlashcard", 
      element: <ProtectedRoute allowedRoles={[1,2]}>
                    <UserFlashcard />
                </ProtectedRoute> },
      { path: "/content/userFlashcard/view/:fcid", 
        element: <ProtectedRoute allowedRoles={[1,2]}>
                    <UserFlashcardView /> 
                  </ProtectedRoute> },
    ],
    errorElement: <ErrorComponent />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

function App() {
  return (
    <>
      <div className="App"> 
        <Suspense fallback={<div>Loading...</div>}>
          <RouterProvider router={router} /> 
        </Suspense>
      </div>
      <ToastContainer autoClose={3000} />
    </>
  );
}

export default App;
